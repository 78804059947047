import "./App.scss";

import Logo from "./assets/NC_LOGO_2 2.png";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

Splitting();

function App() {
  return (
    <div class="container">
      <div class="logo">
        <img src={Logo} alt="ninja-capital-logo" />
      </div>
      <div class="email">
        <a
          href="https://docs.ninjaprotocol.io/capital/cap"
          target="_blank"
          class="nav__link"
          rel="noreferrer"
        >
          $NINJA
        </a>
      </div>
    </div>
  );
}

export default App;
